import { PLAN_PROGRAMMES } from '../constants';
import primitives from '@veneer/primitives';

export const getColor = (program) => {
  switch (program) {
    case PLAN_PROGRAMMES.INSTANT_INK_PAPER:
      return primitives.color.lavender5;
    case PLAN_PROGRAMMES.INSTANT_INK:
      return primitives.color.mediumOrchid6;
    case PLAN_PROGRAMMES.INSTANT_TONER:
      return primitives.color.royalBlue7;
    case PLAN_PROGRAMMES.SMB:
      return primitives.color.hpBlue6;
    case PLAN_PROGRAMMES.SMB_MONO:
      return primitives.color.warmGray12;
    default:
      return primitives.color.mediumOrchid6;
  }
};
