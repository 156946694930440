import styled from 'styled-components';
import primitives from '@veneer/primitives';
import VeneerModal from '@veneer/core/dist/scripts/modal';
import ARNImage from '../../../../imgs/yearly-plans-arn-modal.png';

export const Modal = styled(VeneerModal)`
  && {
    .vn-modal--content {
      padding: 0;
    }
    .vn-modal--body {
      display: flex;
      flex-direction: column;
    }
  }
`;
export const Container = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px 48px;
  }
`;

export const Title = styled.h1`
  && {
    font-size: 32px;
    font-family: ${primitives.typography.family0};
    color: ${primitives.color.gray12};
    font-weight: 400;
    line-height: 40px;

    @media (max-width: 575px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const Subtitle = styled.h2`
  && {
    font-size: 18px;
    font-family: ${primitives.typography.family0};
    color: ${primitives.color.gray12};
    font-weight: 400;
    line-height: 24px;
    margin-top: 4px;

    @media (max-width: 575px) {
      margin-top: 8px;
    }
  }
`;

export const DividingLine = styled.div`
  && {
    width: 100%;
    height: 1px;
    border: 1px;
    background-color: #d9d9d9;
    margin-top: 24px;
  }
`;

export const BackgroundContainerImage = styled.div`
  && {
    display: flex;
    width: 100%;
    height: 200px;
    background-image: url(${ARNImage});
    background-size: cover;
    background-position: center;
    border-radius: 16px 16px 0 0;
    align-items: flex-start;
    justify-content: flex-end;

    img {
      cursor: pointer;
      margin-right: 16px;
      margin-top: 16px;
    }
  }
`;
