/* eslint-disable @typescript-eslint/no-unused-vars */
import { ComparePlanProps } from '../types/comparePlanProps';
import AssetsProviderFactory from '../../assets/AssetsProviderFactory';
import { ComparePlanAction } from '../types/comparePlanAction';
import { ComparePlanState } from '../types/comparePlanState';

export const comparePlansInitialState = ({
  plan,
  country,
  language,
  trackClickEvent = (linkId: string, linkPlacement: string): null => null,
  onKeepMonthlyClick
}: ComparePlanProps): ComparePlanState => {
  return {
    plan,
    getText: AssetsProviderFactory.create(language, country).getText.bind(
      AssetsProviderFactory.create(language, country)
    ),
    onKeepMonthlyClick
  };
};

export const comparePlansReducer = (
  state: ComparePlanState,
  action: ComparePlanAction
) => {
  let newState = state;

  if (action.type === 'SET_ASSETS_PROVIDER') {
    if (action.language && action.country) {
      newState = {
        ...state,
        getText: AssetsProviderFactory.create(
          action.language,
          action.country
        ).getText.bind(
          AssetsProviderFactory.create(action.language, action.country)
        )
      };
    }
  }

  return newState;
};
