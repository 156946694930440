/* eslint-disable @typescript-eslint/no-unused-vars */
import AssetsProviderFactory from '../../assets/AssetsProviderFactory';
import { DashboardPlansAction } from '../types/dashboardPlansAction';
import { DashboardPlansState } from '../types/dashboardPlansState';
import { DashboardPlansProps } from '../types/dashboardPlansProps';
import {
  findPlanBySavedPlanGUID,
  defaultPlan
} from '../utils/findPlanBySavedPlanGUID';

export const dashboardPlansInitialState = ({
  plans,
  savedPlanGUID,
  pausedPlanGUID,
  country,
  language,
  defaultPlanGUIDInk = '',
  defaultPlanGUIDToner = '',
  program,
  onPlanChange,
  trackClickEvent = (linkId: string, linkPlacement: string): null => null,
  showPaperCard = false,
  handlePaperCardClick = () => null,
  paperStartingPrice = '',
  subscriptionState = '',
  showYearlyCard = false
}: DashboardPlansProps): DashboardPlansState => {
  const selected = findPlanBySavedPlanGUID(plans, savedPlanGUID);

  return {
    plans,
    selectedPlan: {
      id: selected.plan.id,
      index: selected.index,
      guid: selected.plan.guid,
      plan: selected.plan,
      isLoading: false
    },
    getText: AssetsProviderFactory.create(language, country).getText.bind(
      AssetsProviderFactory.create(language, country)
    ),
    program,
    defaultPlanGUIDInk,
    defaultPlanGUIDToner,
    onPlanChange,
    trackClickEvent,
    showPaperCard,
    handlePaperCardClick,
    paperStartingPrice,
    subscriptionState,
    pausedPlanGUID,
    showYearlyCard
  };
};

export const dashboardPlansReducer = (
  state: DashboardPlansState,
  action: DashboardPlansAction
) => {
  let newState = state;

  switch (action.type) {
    case 'SET_SELECTED_PLAN':
      newState = {
        ...state,
        selectedPlan: action.selectedPlan || {
          id: 0,
          index: 0,
          guid: '',
          plan: defaultPlan,
          isLoading: false
        }
      };
      break;

    case 'SET_ASSETS_PROVIDER':
      if (action.language && action.country) {
        newState = {
          ...state,
          getText: AssetsProviderFactory.create(
            action.language,
            action.country
          ).getText.bind(
            AssetsProviderFactory.create(action.language, action.country)
          )
        };
      }
      break;

    case 'SET_SUBSCRIPTION_STATE':
      if (action.subscriptionState) {
        newState = {
          ...state,
          subscriptionState: action.subscriptionState
        };
      }
      break;

    case 'SET_PAUSED_PLAN_GUID_STATE':
      if (action.pausedPlanGUID) {
        newState = {
          ...state,
          pausedPlanGUID: action.pausedPlanGUID
        };
      }
      break;
  }
  return newState;
};
