import React from 'react';
import { ShellProps } from '../src/common/types/shell';
import projectNames from '../src/common/config/projectNames';
import { ConsumerPlanSelector, LandingPagePlans, PlanSelector } from '../src';
declare global {
  interface Window {
    Shell: ShellProps;
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({ ...props }) {
  const Component = () => {
    const properties = props.properties;

    if (props.properties.componentName === 'ConsumerPlanSelector') {
      return <ConsumerPlanSelector {...properties} />;
    }

    if (props.properties.componentName === 'LandingPagePlans') {
      return <LandingPagePlans {...properties} />;
    }

    if (props.properties.componentName === 'PlanSelector') {
      return <PlanSelector {...properties} />;
    }

    return <h1>ComponentName not found</h1>;
  };

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      {Component()}
    </section>
  );
}
