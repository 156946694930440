import React from 'react';
import { useGetText } from '../../hooks/useGetText';
import { TitleContainer, Circle, CardTitleContainer } from './styles';

interface PlanNameProps {
  planName?: string;
  circlesNumber?: number;
  dataTestId?: string;
  blueColor?: boolean;
}

export const PlanName: React.FC<PlanNameProps> = ({
  planName,
  circlesNumber = 0,
  blueColor = false,
  dataTestId
}) => {
  const getText = useGetText();

  return (
    <CardTitleContainer>
      {Array.from({ length: circlesNumber }, (_, i) => {
        return <Circle key={i} filled size={12} blueColor={blueColor} />;
      })}
      <TitleContainer data-testid={`${dataTestId}-plan-name-title`}>
        <h1>{getText(`plans_selector.common.plans_frequency.${planName}`)}</h1>
      </TitleContainer>
    </CardTitleContainer>
  );
};
