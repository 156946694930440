import React from 'react';
import {
  Container,
  TitleContainer,
  Card,
  PlanInfoContainer,
  TonerPlansLink
} from './styles';
import {
  useGetText,
  useDispatch,
  usePlanSelectorV3State
} from '../../../hooks';
import inkOnlyImage from '../../../../../../imgs/ink-only.png';
import inkPaperImage from '../../../../../../imgs/ink-paper.png';
import { ComboBoxPlanSelector } from '../ComboBoxPlanSelector';
import { PlanInfo } from '../PlanInfo';

export const PlanSelectorV3Content: React.FC = () => {
  const getText = useGetText();
  const dispatch = useDispatch();
  const { selectedPlan, plans } = usePlanSelectorV3State();
  const inkPlan = plans.i_ink.filter(
    (plan) => plan.guid == selectedPlan.guid
  )[0];
  const inkPaperPlan = plans.i_ink_paper.filter(
    (plan) => plan.guid == selectedPlan.guid
  )[0];
  const cardContent = (
    <>
      <ComboBoxPlanSelector />
      <PlanInfoContainer>
        <PlanInfo
          plan={inkPlan}
          planInfo={{
            img: inkOnlyImage,
            imgTitle: 'Photo of Hp ink cartridges',
            title: getText('plans_selector.ink_plan'),
            description: getText('plans_selector.never_run_out_of_hp_ink'),
            learnMore: false
          }}
          attributes={[
            getText('plans_selector.original_hp_ink'),
            getText('plans_selector.ink_cartridge_recycling'),
            getText('plans_selector.shipping_included')
          ]}
          isLastChild={false}
        />
        <PlanInfo
          plan={inkPaperPlan}
          planInfo={{
            img: inkPaperImage,
            imgTitle: 'Photo of Hp paper and ink cartridges',
            title: getText('plans_selector.ink_paper_plan'),
            description: getText(
              'plans_selector.never_run_out_of_print_supplies'
            ),
            learnMore: true
          }}
          attributes={[
            getText('plans_selector.original_hp_ink'),
            getText('plans_selector.high_quality_hp_paper'),
            getText('plans_selector.ink_cartridge_recycling'),
            getText('plans_selector.shipping_included')
          ]}
          isLastChild
        />
      </PlanInfoContainer>
    </>
  );

  const openPlansModal = () => {
    if (dispatch) {
      dispatch({ type: 'SHOW_PLANS_MODAL', showPlansModal: true });
    }
  };

  return (
    <Container data-testid="plan-selector-v3-content-container">
      <TitleContainer>
        <h1>{getText('plans_selector.plan_modal.select_printing_plan')}</h1>
      </TitleContainer>
      <Card appearance="dropShadow" content={cardContent} />
      <TonerPlansLink data-testid="plan-selector-v3-toner-plans-link-container">
        {getText('plans_selector.looking_for_toner_plans')}{' '}
        <a
          onClick={() => openPlansModal()}
          data-testid="plan-selector-v3-toner-plans-link"
        >
          {getText('plans_selector.view_toner_plans')}
        </a>
      </TonerPlansLink>
    </Container>
  );
};
