import { ComparePlanAction } from '../types/comparePlanAction';

export const setAssetsProviderAction = (
  language: string,
  country: string
): ComparePlanAction => {
  return {
    type: 'SET_ASSETS_PROVIDER',
    language,
    country
  };
};
