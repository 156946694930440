import { DashboardPlansAction } from '../types/dashboardPlansAction';

export const setAssetsProviderAction = (
  language: string,
  country: string
): DashboardPlansAction => ({
  type: 'SET_ASSETS_PROVIDER',
  language,
  country
});
