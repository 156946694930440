import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { breakpointsHelper } from '../../../../../../../common/utils/breakpointsHelper';
interface ContainerProps {
  isLastChild?: boolean;
}
export const Container = styled.div<ContainerProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 0px 0px 56px;
    background-color: white;
    border-radius: ${(props) =>
      props.isLastChild ? '0px 0px 0px 12px' : '0px'};

    @media (max-width: ${breakpointsHelper.medium_min_768px}) {
      padding: 38px 0px 0px 24px;
    }
    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      padding: 24px 0px 0px 16px;
    }
  }
`;

export const ItemsContainer = styled.div<ContainerProps>`
  && {
    display: flex;
    flex-direction: column;
    border-bottom: ${(props) =>
      props.isLastChild ? '0px' : '1px solid #e5e5e5'};
    padding-bottom: 32px;

    img {
      width: 54px;
      height: 54px;
    }

    @media (max-width: ${breakpointsHelper.medium_min_768px}) {
      height: 100%;

      img {
        width: 71px;
        height: 71px;
      }
    }
    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      flex-direction: row;
      height: 100%;
      border-bottom: 0px;
      padding-bottom: 0px;

      img {
        margin-top: 5px;
        width: 56px;
        height: 56px;
      }
    }
  }
`;

export const TitleContainer = styled.div`
  && {
    margin-left: 0px;

    @media (max-width: ${breakpointsHelper.small_min_576px}) {
      margin-left: 16px;
    }
  }
`;

export const Title = styled.div`
  && {
    font-family: ${primitives.typography.family0};
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    margin-top: 16px;

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      margin: 0px;
    }
  }
`;

export const Description = styled.div`
  && {
    font-family: ${primitives.typography.family0};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-top: 4px;
  }
`;

export const LearnMore = styled.a`
  && {
    font-family: ${primitives.typography.family0};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    cursor: pointer;
  }
`;
