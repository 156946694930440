import React from 'react';
import IconCheckmark from '@veneer/core/dist/scripts/icons/icon_checkmark';
import { useGetText } from '../../hooks/useGetText';

import { Container, InfoContainer } from './styles';

interface PlanInfoMessageProps {
  rollover: number;
  overageBlockSize: number;
  overageBlockPrice: string;
  program: string;
  dataTestId?: string;
}
export const PlanInfoMessage: React.FC<PlanInfoMessageProps> = ({
  rollover,
  overageBlockSize,
  overageBlockPrice,
  program,
  dataTestId
}) => {
  const getText = useGetText();
  return (
    <Container>
      <InfoContainer data-testid={`${dataTestId}-rollover`}>
        <IconCheckmark size={16} color="gray12" style={{ flex: 'none' }} />
        <span>
          {getText('plans_selector.rollover_up_to_x_pages', {
            pages: rollover.toString()
          })}
        </span>
      </InfoContainer>
      <InfoContainer data-testid={`${dataTestId}-additional-pages`}>
        <IconCheckmark size={16} color="gray12" style={{ flex: 'none' }} />
        <span>
          {getText('plans_selector.additional_x_pages_for_y', {
            pages: overageBlockSize.toString(),
            price: overageBlockPrice
          })}
        </span>
      </InfoContainer>
      {program === 'i_ink_paper' && (
        <InfoContainer data-testid={`${dataTestId}-includes-ink-and-paper`}>
          <IconCheckmark size={16} color="gray12" style={{ flex: 'none' }} />
          <span>{getText('plans_selector.includes_ink_and_paper')}</span>
        </InfoContainer>
      )}
    </Container>
  );
};
