import styled from 'styled-components';

export const PlanCardContainer = styled.div`
  && {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
      width: 99%;
    }
  }
`;
