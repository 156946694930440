import React from 'react';
import { useDashboardPlansState } from '../../hooks/useDashboardPlansState';
import { PlanCard } from '../PlanCard';
import { PLAN_PROGRAMMES } from '../../../common/constants';
import { PlanCardContainer } from './styles';
import { PaperCard } from '../PaperCard';
import { YearlyCard } from '../YearlyCard';

export const DashboardPlansContent: React.FC = () => {
  const { plans, program, showPaperCard, showYearlyCard } =
    useDashboardPlansState();
  return (
    <PlanCardContainer>
      {showYearlyCard && <YearlyCard />}
      {plans.map((plan, index) => {
        return <PlanCard key={index} index={index} plan={plan} />;
      })}
      {showPaperCard && program == PLAN_PROGRAMMES.INSTANT_INK && <PaperCard />}
    </PlanCardContainer>
  );
};
