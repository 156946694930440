import React from 'react';

import { ButtonsContainer, BackButton, SelectButton } from './styles';
import { useGetText } from '../../../../../../hooks/useGetText';
import { useConsumerPlansState } from '../../../../../../hooks/useConsumerPlansState';
import { useWindowSize } from '../../../../../../../../common/hooks/useWindowSize';
import { MIN_TABLET_RESOLUTION } from '../../helpers/resolutionHelper';
import { useDispatch } from '../../../../../../hooks/useDispatch';

interface PlanInfoButtonsProps {
  setShowIndividualPlanInfo: (state: boolean) => void;
}
export const PlanInfoButtons: React.FC<PlanInfoButtonsProps> = ({
  setShowIndividualPlanInfo
}) => {
  const { selectedPlan, onPlanChange, isSavingPlan, isHPX } =
    useConsumerPlansState();
  const getText = useGetText();
  const resolution = useWindowSize();
  const isMobile = resolution < MIN_TABLET_RESOLUTION;
  const dispatch = useDispatch();

  const savePlanChange = async () => {
    if (dispatch) {
      try {
        dispatch({ type: 'SAVE_PLAN' });
        await onPlanChange(selectedPlan.id, selectedPlan.isInkPaperPlan);
        dispatch({ type: 'SAVE_PLAN_SUCCESS' });
        setShowIndividualPlanInfo(false);
      } catch (error) {
        console.log(error);
        dispatch({ type: 'SAVE_PLAN_FAIL' });
        setShowIndividualPlanInfo(false);
      }
    }
  };

  return (
    <ButtonsContainer $isMobile={isMobile}>
      <BackButton
        $isMobile={isMobile}
        appearance="secondary"
        onClick={() => setShowIndividualPlanInfo(false)}
      >
        {getText('plans_selector.back')}
      </BackButton>
      <SelectButton
        appearance={isHPX ? 'primary' : 'secondary'}
        loading={isSavingPlan}
        onClick={() => savePlanChange()}
      >
        {getText('plans_selector.plan_modal.select_plan')}
      </SelectButton>
    </ButtonsContainer>
  );
};
