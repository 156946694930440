import React from 'react';
import { WideCard } from '../WideCard';
import { YearlyPlansCard } from '../YearlyPlansCard';
import { useGetText } from '../../hooks/useGetText';
import { Carousel } from '../Carousel';
import { PlanCardContainer } from './styles';
import { ComparePlanProps } from '../../types/comparePlanProps';

export const YearlyPlansContent: React.FC<ComparePlanProps> = (props) => {
  console.log(props);
  const getText = useGetText();
  return (
    <>
      <h5>{getText('plans_selector.yearly.modals.all_yearly_plans.title')}</h5>
      <WideCard pagesPerMonth="100" pricePerMonth="6.99" />
      <Carousel>
        <PlanCardContainer>
          <YearlyPlansCard
            numberOfCircles={1}
            pagesPerYear="120"
            pricePerYear="$15.88"
            savedPricePerYear="$74.99"
          />
          <YearlyPlansCard
            numberOfCircles={2}
            pagesPerYear="600"
            pricePerYear="$83.88"
            savedPricePerYear="$74.99"
          />
          <YearlyPlansCard
            numberOfCircles={3}
            pagesPerYear="1200"
            pricePerYear="$167.88"
            savedPricePerYear="$150.99"
            hasTag={true}
          />
          <YearlyPlansCard
            numberOfCircles={4}
            pagesPerYear="3600"
            pricePerYear="$83.88"
            savedPricePerYear="$74.99"
          />
          <YearlyPlansCard
            numberOfCircles={5}
            pagesPerYear="8400"
            savedPricePerYear="$251.88"
            pricePerYear="$225.00"
          />
          <YearlyPlansCard
            numberOfCircles={6}
            pagesPerYear="8400"
            savedPricePerYear="$335.85"
            pricePerYear="$300.88"
          />
          <YearlyPlansCard
            numberOfCircles={7}
            pagesPerYear="18000"
            savedPricePerYear="$659.88"
            pricePerYear="$595.00"
          />
        </PlanCardContainer>
      </Carousel>
      <b>
        {getText('plans_selector.yearly.modals.all_yearly_plans.print_more')}
      </b>
      <p>
        {getText('plans_selector.yearly.modals.all_yearly_plans.renew_plan')}
      </p>
    </>
  );
};
