import React from 'react';
import {
  LargeFont,
  PriceStrike,
  YearlyPlanCard,
  YearlyPlanContainer,
  CardContent,
  SaveText,
  PlaceHolder,
  SmallFont,
  CardText
} from './styles';
import { PlanTag } from '../PlanTag';
import { PlanName } from '../PlanName';
import Button from '@veneer/core/dist/scripts/button';

interface YearlyPlansCardProps {
  pagesPerYear: string;
  pricePerYear: string;
  savedPricePerYear: string;
  numberOfCircles?: number;
  tagColor?: string;
  hasTag?: boolean;
  dataTestId?: string;
}

export const YearlyPlansCard: React.FC<YearlyPlansCardProps> = ({
  pagesPerYear,
  savedPricePerYear,
  pricePerYear,
  numberOfCircles,
  dataTestId,
  tagColor = '#E6E6E6',
  hasTag = false
}) => {
  return (
    <>
      <YearlyPlanCard data-testid={dataTestId}>
        {hasTag ? (
          <PlanTag tagTitle="Your Plan as yearly" color={tagColor} />
        ) : (
          <PlaceHolder />
        )}
        <YearlyPlanContainer>
          <PlanName
            planName={'moderate'}
            circlesNumber={numberOfCircles}
            blueColor={true}
          />
          <CardContent>
            <CardText>
              <p>
                <LargeFont>{pagesPerYear}</LargeFont> pages/year
              </p>
              <p>
                <PriceStrike>{pricePerYear}</PriceStrike>
                <SmallFont> /year</SmallFont>
              </p>
              <p>{savedPricePerYear}</p>
              <SaveText>SAVE $X.XX/YEAR</SaveText>
            </CardText>
            <Button
              style={{
                height: '36px',
                borderRadius: '8px',
                border: '1px solid #0076AD'
              }}
              appearance="ghost"
            >
              Select Plan
            </Button>
          </CardContent>
        </YearlyPlanContainer>
      </YearlyPlanCard>
    </>
  );
};
