import React, { useEffect, useMemo, useReducer } from 'react';
import { DashboardPlansContext } from './context/dashboardPlansContext';
import {
  dashboardPlansInitialState,
  dashboardPlansReducer
} from './reducers/dashboardPlansReducer';
import { setAssetsProviderAction } from './actions/setAssetsProviderAction';
import { setSelectedPlanAction } from './actions/setSelectedPlanAction';
import { setSubscriptionStateAction } from './actions/setSubscriptionStateAction';
import { setPausedPlanGUID } from './actions/setPausedPlanGUID';
import { DashboardPlansContent } from './components/DashboardPlanContent';
import { DashboardPlansProps } from './types/dashboardPlansProps';
import { findPlanBySavedPlanGUID } from './utils/findPlanBySavedPlanGUID';

export const DashboardPlanSelector: React.FC<DashboardPlansProps> = (props) => {
  const [dashboardPlansState, dispatch] = useReducer(
    dashboardPlansReducer,
    dashboardPlansInitialState(props)
  );

  useEffect(() => {
    dispatch(setAssetsProviderAction(props.language, props.country));
  }, [props.language, props.country]);

  useEffect(() => {
    dispatch(setSubscriptionStateAction(props.subscriptionState));
  }, [props.subscriptionState]);

  useEffect(() => {
    dispatch(setPausedPlanGUID(props.pausedPlanGUID));
  }, [props.pausedPlanGUID]);

  useEffect(() => {
    const selected = findPlanBySavedPlanGUID(props.plans, props.savedPlanGUID);
    dispatch(
      setSelectedPlanAction({
        id: selected.plan.id,
        index: selected.index,
        guid: selected.plan.guid,
        plan: selected.plan,
        isLoading: false
      })
    );
  }, [props.savedPlanGUID]);

  const contextValue = useMemo(
    () => ({ dashboardPlansState, dispatch }),
    [dashboardPlansState, dispatch]
  );

  return (
    <DashboardPlansContext.Provider value={contextValue}>
      <DashboardPlansContent />
    </DashboardPlansContext.Provider>
  );
};
