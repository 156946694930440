import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { breakpointsHelper } from '../../../../../common/utils/breakpointsHelper';
interface ContainerProps {
  isComboBoxSection?: boolean;
  applyTopRightBorder?: boolean;
  applyBottomRightBorder?: boolean;
}
export const Container = styled.div<ContainerProps>`
  && {
    display: flex;
    width: 100%;
    height: auto;
    @media (max-width: ${breakpointsHelper.medium_min_768px}) {
      flex-direction: ${(props) =>
        props.isComboBoxSection ? 'column' : 'row'};
    }
    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      flex-direction: column;
    }
  }
`;

export const LeftContentContainer = styled.div`
  && {
    display: flex;
    width: 100%;
    height: auto;
    background-color: ${primitives.color.white};
    border-radius: 12px 12px 0px 12px;

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      border-radius: 12px 12px 0px 0px;
    }
  }
`;

export const RightContentContainer = styled.div<ContainerProps>`
  && {
    display: flex;
    width: 100%;
    min-width: 560px;
    height: auto;
    background-color: ${primitives.color.gray0};
    border-top-right-radius: ${(props) =>
      props.applyTopRightBorder ? '12px' : '0px'};
    border-bottom-right-radius: ${(props) =>
      props.applyBottomRightBorder ? '12px' : '0px'};

    @media (max-width: ${breakpointsHelper.medium_min_768px}) {
      min-width: 286px;
      border-top-right-radius: 0px;
    }
  }
`;
