import React, { useState } from 'react';
import IconPriceTag from '@veneer/core/dist/scripts/icons/icon_price_tag';
import Button from '@veneer/core/dist/scripts/button';
import primitives from '@veneer/primitives';
import {
  YearlyCardContainer,
  ButtonContainer,
  TitleContainer,
  Description,
  ImageContainer,
  YearlyImage,
  NewTag,
  IconPriceTagContainer,
  NewTagContent
} from './styles';
import { useGetText } from '../../hooks/useGetText';
import Modal from '@veneer/core/dist/scripts/modal';
import { ComparePlan } from '../../../YearlyPlans/ComparePlan';

export const YearlyCard: React.FC = () => {
  const getText = useGetText();
  const [showComparePlans, setShowComparePlans] = useState(false);

  return (
    <>
      <YearlyCardContainer
        data-testid="dashboard-plan-selector-paper-card-container"
        onClick={() => setShowComparePlans(true)}
      >
        <ImageContainer>
          <YearlyImage />
        </ImageContainer>
        <NewTag>
          <NewTagContent>
            <IconPriceTagContainer>
              <IconPriceTag color={primitives.color.white} size={16} />
            </IconPriceTagContainer>
            <p>{getText('plans_selector.yearly_card.new_tag')}</p>
          </NewTagContent>
        </NewTag>
        <TitleContainer>
          <h1>{getText('plans_selector.yearly_card.freedom_print')}</h1>
        </TitleContainer>
        <Description
          dangerouslySetInnerHTML={{
            __html: getText('plans_selector.yearly_card.card_content')
          }}
        />
        <ButtonContainer>
          <Button>
            {getText('plans_selector.yearly_card.compare_yearly')}
          </Button>
        </ButtonContainer>
      </YearlyCardContainer>
      <Modal
        show={showComparePlans}
        maxWidth={920}
        closeButton
        onClose={() => setShowComparePlans(false)}
      >
        <ComparePlan
          country="US"
          language="en"
          onKeepMonthlyClick={() => setShowComparePlans(false)}
        />
      </Modal>
    </>
  );
};
