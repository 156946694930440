import React from 'react';
import { PlanCard } from '../PlanCard.tsx';
import { CompareCardContainer } from './styles';

export const ComparePlanCardContainer: React.FC = () => {
  return (
    <>
      <CompareCardContainer>
        <PlanCard
          planName={'moderate'}
          pagesTotal={100}
          pricesPage={'$6.99'}
          circlesNumber={3}
        />
        <PlanCard
          planName={'moderate'}
          pagesTotal={1200}
          pricesPage={'$16.99'}
          circlesNumber={3}
          isYearly={true}
          yearlyPrice={'$83.88'}
        />
      </CompareCardContainer>
      <hr style={{ marginTop: '20px' }} />
    </>
  );
};
