import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { ComparePlanProps } from '../types/comparePlanProps';
import {
  comparePlansInitialState,
  comparePlansReducer
} from '../reducers/comparePlansReducer';
import { setAssetsProviderAction } from '../actions/setAssetsProviderAction';
import { ComparePlanContext } from '../context/comparePlanContext';
import { ComparePlanCardContent } from '../components/ComparePlanCardContent/ComparePlanContent';
import { YearlyPlansContent } from '../components/YearlyPlansContent';

export const ComparePlan: React.FC<ComparePlanProps> = (props) => {
  const [comparePlanState, dispatch] = useReducer(
    comparePlansReducer,
    comparePlansInitialState(props)
  );

  const [showAllYearlyPlans, setShowAllYearlyPlans] = useState(false);

  useEffect(() => {
    dispatch(setAssetsProviderAction(props.language, props.country));
  }, [props.language, props.country]);

  const contextValue = useMemo(
    () => ({ comparePlanState, dispatch }),
    [comparePlanState, dispatch]
  );

  return (
    <ComparePlanContext.Provider value={contextValue}>
      {showAllYearlyPlans ? (
        <YearlyPlansContent {...props} />
      ) : (
        <ComparePlanCardContent setShowYearlyPlans={setShowAllYearlyPlans} />
      )}
    </ComparePlanContext.Provider>
  );
};
