import React, { useEffect, useMemo, useReducer } from 'react';
import { planSelectorV3Context } from '../context/planSelectorV3Context';
import {
  planSelectorV3InitialState,
  planSelectorV3Reducer
} from '../reducers/planSelectorV3Reducer';
import { setAssetsProviderAction } from '../actions/setAssetsProviderAction';
import { PlanSelectorV3Content } from './components/PlanSelectorV3Content';
import { PlanSelectorV3Props } from '../types/planSelectorV3Props';
import { PlansModal } from './components/PlansModal';

export const PlanSelectorV3: React.FC<PlanSelectorV3Props> = (props) => {
  const [planSelectorV3State, dispatch] = useReducer(
    planSelectorV3Reducer,
    planSelectorV3InitialState(props)
  );

  useEffect(() => {
    dispatch(setAssetsProviderAction(props.language, props.country));
  }, [props.language, props.country]);

  const contextValue = useMemo(
    () => ({ planSelectorV3State, dispatch }),
    [planSelectorV3State, dispatch]
  );

  return (
    <planSelectorV3Context.Provider value={contextValue}>
      <PlanSelectorV3Content />
      <PlansModal {...props} />
    </planSelectorV3Context.Provider>
  );
};
