import React, { Dispatch, SetStateAction } from 'react';
import { useGetText } from '../../hooks/useGetText';
import { ComparePlanCardContainer } from '../ComparePlanCardContainer';
import { Link } from './styles';

interface ComparePlanCardContentProps {
  setShowYearlyPlans?: Dispatch<SetStateAction<boolean>>;
}

export const ComparePlanCardContent: React.FC<ComparePlanCardContentProps> = ({
  setShowYearlyPlans
}) => {
  const getText = useGetText();

  return (
    <>
      <h2>{getText('plans_selector.yearly.modals.title_switch_plan')}</h2>
      <span>
        {getText('plans_selector.yearly.modals.compare_plans_to_change', {
          savings: '10%'
        })}
      </span>
      <ComparePlanCardContainer />
      <Link onClick={() => setShowYearlyPlans(true)}>
        {getText('plans_selector.yearly.modals.see_all_yearly_plans')}
      </Link>
    </>
  );
};
