import React from 'react';
import { StyledSlider, SliderCard, Container, FlexContainer } from './styles';
import { ArrowButton } from './ArrowButton';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import primitives from '@veneer/primitives';

interface CarouselProps {
  children: React.ReactNode;
}

export const Carousel: React.FC<CarouselProps> = ({ children }) => {
  const resolution = useWindowSize();
  const MIN_DESKTOP_RESOLUTION = primitives.layout.breakpoint2;
  const isNotDesktop = resolution > MIN_DESKTOP_RESOLUTION;
  return (
    <FlexContainer>
      <Container data-testid="landing-page-plans-scrollview-container">
        {isNotDesktop && (
          <ArrowButton
            direction="left"
            onClick={() => {
              const scrollLeft = document.getElementById('carousel-plans-card');
              if (scrollLeft === null) return;
              scrollLeft.scrollLeft -= 200;
            }}
          />
        )}

        <StyledSlider
          data-testid={'carousel-plans-card'}
          id={'carousel-plans-card'}
        >
          <SliderCard id={'sliderCard'}>{children}</SliderCard>
        </StyledSlider>

        {isNotDesktop && (
          <ArrowButton
            direction="right"
            onClick={() => {
              const scrollRight = document.getElementById(
                'carousel-plans-card'
              );
              if (scrollRight === null) return;
              scrollRight.scrollLeft += 200;
            }}
          />
        )}
      </Container>
    </FlexContainer>
  );
};
