import React from 'react';
import { PlanName } from '../PlanName';
import { PlanTag } from '../PlanTag';
import { PagesPriceInfo } from '../PagesPriceInfo';
import { PlanInfoMessage } from '../PlanInfoMessage';

import {
  PlanCardContainer,
  TransparentTag,
  InfoContainer,
  HorizontalLine,
  ButtonContainer,
  ColorContainer
} from './styles';
import { Plan } from '../../types/dashboardPlansState';
import { useDashboardPlansState } from '../../hooks/useDashboardPlansState';
import Button from '@veneer/core/dist/scripts/button';
import { useGetText } from '../../hooks/useGetText';
import { useDispatch } from '../../hooks/useDispatch';
import { setSelectedPlanAction } from '../../actions/setSelectedPlanAction';
import {
  INITIATED_UNSUBSCRIBE,
  PLAN_PROGRAMMES,
  SUBSCRIBED_NO_PENS
} from '../../../common/constants';

interface PlanCardProps {
  index: number;
  plan: Plan;
}
export const PlanCard: React.FC<PlanCardProps> = ({ index, plan }) => {
  const {
    selectedPlan,
    program,
    onPlanChange,
    subscriptionState,
    pausedPlanGUID,
    defaultPlanGUIDInk,
    defaultPlanGUIDToner
  } = useDashboardPlansState();
  const getText = useGetText();
  const isCurrentPlan = plan.guid === selectedPlan.guid;
  const isPausedPlan = plan.guid === pausedPlanGUID;
  const dispatch = useDispatch();
  const isInitiatedUnsubscribe = subscriptionState === INITIATED_UNSUBSCRIBE;
  const isConsumerInk =
    program === PLAN_PROGRAMMES.INSTANT_INK ||
    program === PLAN_PROGRAMMES.INSTANT_INK_PAPER;
  const defaultPlanGUID = isConsumerInk
    ? defaultPlanGUIDInk
    : defaultPlanGUIDToner;

  const tagName = () => {
    if (isPausedPlan) {
      return 'paused_plan';
    }

    if (isCurrentPlan) {
      return 'current_plan';
    }

    if (isConsumerInk) {
      return 'most_popular';
    } else {
      return 'recommended';
    }
  };

  return (
    <PlanCardContainer
      data-testid={'plans-selector-plan-card-container-' + index}
      isSelected={isCurrentPlan || isPausedPlan}
      disableClick={(isCurrentPlan && !isPausedPlan) || isInitiatedUnsubscribe}
      onClick={() => {
        if (subscriptionState === SUBSCRIBED_NO_PENS) {
          dispatch(
            setSelectedPlanAction({
              id: plan.id,
              index: index,
              guid: plan.guid,
              plan: plan,
              isLoading: true
            })
          );
        }

        onPlanChange(plan);
      }}
    >
      {plan.guid === defaultPlanGUID || isCurrentPlan || isPausedPlan ? (
        <PlanTag
          dataTestId={'plans-selector-plan-card-' + index}
          tagTitle={tagName()}
          isSelected={isCurrentPlan || isPausedPlan}
        />
      ) : (
        <TransparentTag />
      )}
      <InfoContainer>
        <PlanName
          dataTestId={'plans-selector-plan-card-' + index}
          planName={plan.frequency}
          circlesNumber={index + 1}
        />
        <PagesPriceInfo
          dataTestId={'plans-selector-plan-card-' + index}
          pages={plan.pages}
          price={plan.price}
        />

        <HorizontalLine />
      </InfoContainer>
      <PlanInfoMessage
        rollover={plan.rollover}
        overageBlockSize={plan.overageBlockSize}
        overageBlockPrice={plan.overageBlockPrice}
        program={program}
        dataTestId={'plans-selector-plan-card-' + index}
      />
      <ButtonContainer>
        <Button
          appearance={isPausedPlan ? 'primary' : 'secondary'}
          loading={isCurrentPlan && !isPausedPlan && selectedPlan.isLoading}
          disabled={(isCurrentPlan && !isPausedPlan) || isInitiatedUnsubscribe}
          data-testid={
            isPausedPlan
              ? 'plans-selector-resume-plan-button-' + index
              : 'plans-selector-select-plan-button-' + index
          }
        >
          {isPausedPlan
            ? getText('plans_selector.resume_plan')
            : getText('plans_selector.select_plan')}
        </Button>
      </ButtonContainer>
      <div style={{ paddingTop: 24 }}>
        <ColorContainer
          program={program == 'i_ink_paper' ? 'i_ink' : program}
        />
      </div>
    </PlanCardContainer>
  );
};
