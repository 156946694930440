import { Plan } from '../types/dashboardPlansState';

export const defaultPlan = {
  id: 0,
  guid: '',
  price: '',
  pages: 0,
  overageBlockPrice: '',
  overageBlockSize: 0,
  rollover: 0,
  frequency: '',
  addons: [] as []
};

export const findPlanBySavedPlanGUID = (plans: Plan[], savedPlanGUID) => {
  const index = plans.findIndex((plan) => plan.guid === savedPlanGUID);
  const plan = index !== -1 ? plans[index] : defaultPlan;
  return { plan, index };
};
