import React from 'react';
import { AutomaticRenewalNotice } from '@jarvis/react-instant-ink-automatic-renewal-notice';
import { useGetText } from '../../../../hooks/useGetText';

import { TOSCheckbox, ButtonContainer, EnrollButton } from './styles';

export const JarvisARN: React.FC<{ closeModal: () => void }> = ({
  closeModal
}) => {
  const [acceptedTermsOfService, setAcceptedTermsOfService] =
    React.useState(false);
  const getText = useGetText();
  return (
    <>
      <AutomaticRenewalNotice
        language="en"
        country="US"
        hasPrepaidBalance
        prepaidBalanceFormatted={'$10'}
        totalFreeMonths={10}
        waiverPages={10}
        cancelUrl={'cancelurl.com'}
        dashboardUrl={'dashbord_url.com'}
        trackClickEvent={(linkId, linkPlacement) =>
          console.log('Tracking Click Event: ', linkId, linkPlacement)
        }
        isV3
      />
      <TOSCheckbox
        id="terms-of-service"
        data-testid="terms-agreement"
        name="termsOfService"
        checked={acceptedTermsOfService}
        onChange={() => setAcceptedTermsOfService((prev) => !prev)}
        label={
          <p
            dangerouslySetInnerHTML={{
              __html: getText(
                'plans_selector.yearly_confirmation.modals.i_have_read_and_agree',
                { hpTermsOfService: '/terms-of-service' }
              )
            }}
          />
        }
      />
      <ButtonContainer>
        <EnrollButton appearance={'secondary'} onClick={() => closeModal()}>
          {getText('plans_selector.cancel')}
        </EnrollButton>
        <EnrollButton disabled={!acceptedTermsOfService} onClick={() => null}>
          {getText('plans_selector.confirm_plan')}
        </EnrollButton>
      </ButtonContainer>
    </>
  );
};
