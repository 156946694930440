import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const YearlyPlanCard = styled.div`
  && {
    cursor: 'none';
    pointer-events: 'none';
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    @media (min-width: 767px) {
      height: 264px;
    }
    width: 200px;
    border-radius: 8px;
    border: 1px solid ${primitives.color.gray3};
    margin-right: 12px;

    @media (max-width: 767px) {
      flex-direction: column;
      width: 99%;
      min-height: 172px;
    }
  }
`;

export const PlaceHolder = styled.div`
  & {
    margin-bottom: 25px;

    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }
`;

export const CardContent = styled.div`
  & {
    display: flex;
    flex-direction: column;

    button {
      width: auto;
      margin-top: auto;
      width: 100%;
      margin-bottom: 26px;
    }
    @media (min-width: 500px) and (max-width: 767px) {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;

      button {
        width: fit-content;
        min-width: 122px;
      }
    }
  }
`;

export const SmallFont = styled.span`
  & {
    font-size: 12px;
  }
`;

export const CardText = styled.div`
  && {
    margin-bottom: 24px;
    margin-top: 8px;
    line-height: 20px;
    font-size: 14px;
  }
`;

export const SaveText = styled.p`
  &&& {
    color: ${primitives.color.cornFlowerBlue8};
    margin-top: 8px;
    font-size: 13px;
    line-height: 16px;
  }
`;

export const LargeFont = styled.span`
  && {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }
`;

export const YearlyPlanContainer = styled.div`
  && {
    padding: 15px 12px 0 12px;
  }
`;

export const PriceStrike = styled.span`
  && {
    position: relative;
    text-decoration: none;
  }
  &&::after {
    content: '';
    position: absolute;
    top: 48%;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${primitives.color.cornFlowerBlue7};
    transform: rotate(9.82deg);
  }
`;
