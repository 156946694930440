import styled from 'styled-components';
import primitives from '@veneer/primitives';
import IconCircle from '@veneer/core/dist/scripts/icons/icon_circle';
import { getColor } from '../../../common/utils/colorSelector';

export const TitleContainer = styled.div`
  &&& {
    h1 {
      font-size: 16px;
      font-weight: 400;
      color: ${primitives.color.gray12};
      line-height: 20px;
      font-family: ${primitives.typography.family0};
      margin-top: 8px;
      margin-bottom: 0px;
      word-break: break-all;
      margin: 0;
    }
  }
`;

export const CardTitleContainer = styled.div`
  && {
    height: 40px;
  }
`;

type CircleProps = {
  program: string;
};

export const Circle = styled(IconCircle)<CircleProps>`
  && {
    margin-right: 6px;
    color: ${(props) => getColor(props.program)};
  }
`;
