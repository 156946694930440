import styled from 'styled-components';
import primitives from '@veneer/primitives';

type ContainerTagProps = {
  isSelected?: boolean;
};
export const TagContainer = styled.div<ContainerTagProps>`
  && {
    display: flex;
    align-items: center;
    width: 100%;
    height: 24px;
    background-color: ${(props) =>
      props.isSelected ? primitives.color.gray12 : '#e6e6e6'};
    border-radius: 7px 7px 0px 0px;
  }
`;

export const TagTitle = styled.span<ContainerTagProps>`
  && {
    font-family: ${primitives.typography.family0};
    color: ${(props) =>
      props.isSelected ? primitives.color.white : primitives.color.gray12};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 4px 0px 4px 16px;
  }
`;
