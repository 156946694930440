import React from 'react';
import { useDashboardPlansState } from '../../hooks/useDashboardPlansState';
import { useGetText } from '../../hooks/useGetText';
import { TitleContainer, Circle, CardTitleContainer } from './styles';

interface PlanNameProps {
  planName?: string;
  circlesNumber?: number;
  dataTestId?: string;
  isInkPaper?: boolean;
}

export const PlanName: React.FC<PlanNameProps> = ({
  planName,
  circlesNumber = 0,
  dataTestId
}) => {
  const { program } = useDashboardPlansState();
  const getText = useGetText();

  return (
    <CardTitleContainer>
      {Array.from({ length: circlesNumber }, (_, i) => {
        return (
          <Circle
            key={i}
            filled
            size={12}
            program={program == 'i_ink_paper' ? 'i_ink' : program}
          />
        );
      })}
      {planName && program != 'i_toner' ? (
        <TitleContainer data-testid={`${dataTestId}-plan-name-title`}>
          <h1>
            {getText(`plans_selector.common.plans_frequency.${planName}`)}
          </h1>
        </TitleContainer>
      ) : (
        <div style={{ height: 30 }} />
      )}
    </CardTitleContainer>
  );
};
