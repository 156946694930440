/* eslint-disable @typescript-eslint/no-unused-vars */
import AssetsProviderFactory from '../../../assets/AssetsProviderFactory';
import { planSelectorV3Action } from '../types/planSelectorV3Action';
import { planSelectorV3State } from '../types/planSelectorV3State';
import { PlanSelectorV3Props } from '../types/planSelectorV3Props';

const findIdBySavedPlanGUID = (
  plans,
  savedPlanGUID,
  defaultPlanGUIDInk,
  program
) => {
  const index = plans[program].findIndex((plan) => plan.guid === savedPlanGUID);
  const plan =
    index !== -1
      ? plans[program][index]
      : plans[program].find((plan) => plan.guid === defaultPlanGUIDInk);
  return { plan, index };
};

export const planSelectorV3InitialState = ({
  plans,
  savedPlanGUID,
  country,
  language,
  defaultPlanGUIDInk = '',
  defaultPlanGUIDToner = '',
  program,
  onPlanChange,
  trackClickEvent = (linkId: string, linkPlacement: string): null => null,
  renderLearnMore
}: PlanSelectorV3Props): planSelectorV3State => {
  const selected = findIdBySavedPlanGUID(
    plans,
    savedPlanGUID,
    defaultPlanGUIDInk,
    program
  );

  return {
    plans,
    selectedPlan: selected.plan,
    getText: AssetsProviderFactory.create(language, country).getText.bind(
      AssetsProviderFactory.create(language, country)
    ),
    program,
    savedPlanGUID,
    defaultPlanGUIDInk,
    defaultPlanGUIDToner,
    onPlanChange,
    trackClickEvent,
    renderLearnMore,
    showPlansModal: false
  };
};

export const planSelectorV3Reducer = (
  state: planSelectorV3State,
  action: planSelectorV3Action
) => {
  let newState = state;

  switch (action.type) {
    case 'SET_SELECTED_PLAN':
      newState = {
        ...state,
        selectedPlan:
          action.selectedPlan ||
          findIdBySavedPlanGUID(
            state.plans,
            state.savedPlanGUID,
            state.defaultPlanGUIDInk,
            state.program
          ).plan
      };
      break;

    case 'SET_SELECTED_PROGRAM':
      newState = {
        ...state,
        program: action.program || ''
      };
      break;

    case 'SET_ASSETS_PROVIDER':
      if (action.language && action.country) {
        newState = {
          ...state,
          getText: AssetsProviderFactory.create(
            action.language,
            action.country
          ).getText.bind(
            AssetsProviderFactory.create(action.language, action.country)
          )
        };
      }
      break;
    case 'SAVE_PLAN':
      newState = {
        ...state,
        isSavingPlan: true
      };
      break;
    case 'SAVE_PLAN_SUCCESS':
      newState = {
        ...state,
        isSavingPlan: false
      };
      break;
    case 'SAVE_PLAN_FAIL':
      newState = {
        ...state,
        isSavingPlan: false
      };
      break;
    case 'SHOW_PLANS_MODAL':
      newState = {
        ...state,
        showPlansModal: action.showPlansModal || false
      };
      break;
  }
  return newState;
};
