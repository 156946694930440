import React from 'react';
import { CardContainer, Divider, LargeFont } from './styles';
import { useGetText } from '../../hooks/useGetText';

interface WideCardProps {
  pagesPerMonth: string;
  pricePerMonth: string;
  dataTestId?: string;
}

export const WideCard: React.FC<WideCardProps> = ({
  pagesPerMonth,
  pricePerMonth
}) => {
  const getText = useGetText();
  return (
    <>
      <CardContainer data-testid="currently-plan-wide-card">
        <p>
          {getText(
            'plans_selector.yearly.modals.all_yearly_plans.monthly_plan'
          )}
        </p>
        <div>
          <p>
            <LargeFont>{pagesPerMonth}</LargeFont>{' '}
            {getText('plans_selector.plan_card.pages_per_month')}
            <Divider />
            <LargeFont>{pricePerMonth}</LargeFont>{' '}
            {getText('plans_selector.plan_card.per_month')}
          </p>
        </div>
      </CardContainer>
    </>
  );
};
