import styled from 'styled-components';

export const CompareCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 32px;

  @media (max-width: 550px) {
    justify-content: center;
    flex-direction: column;
  }
`;
