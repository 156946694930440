import primitives from '@veneer/primitives';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 4px 16px 0px 16px;
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-top: 8px;

  span {
    font-size: 12px;
    font-weight: 400;
    color: ${primitives.color.gray12};
    line-height: 16px;
    font-family: ${primitives.typography.family0};
    margin-left: 4px;
  }
`;
