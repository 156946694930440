import React from 'react';
import { useGetText } from '../../hooks/useGetText';

import {
  Container,
  PagesContainer,
  PriceContainer,
  PriceContent
} from './styles';

interface PagesPriceInfoProps {
  pages: number;
  price: string;
  yearlyPrice?: string;
  dataTestId?: string;
}

export const PagesPriceInfo: React.FC<PagesPriceInfoProps> = ({
  pages,
  price,
  yearlyPrice,
  dataTestId
}) => {
  const getText = useGetText();
  return (
    <Container>
      <PagesContainer data-testid={`${dataTestId}-pages-container`}>
        <h1 data-testid={`${dataTestId}-pages-title`}>{pages}</h1>
        <h2>
          {yearlyPrice
            ? getText('plans_selector.plan_card.pages_per_year')
            : getText('plans_selector.plan_card.pages_per_month')}
        </h2>
      </PagesContainer>
      <PriceContainer data-testid={`${dataTestId}-price-container`}>
        <h1 data-testid={`${dataTestId}-price-title`}>{price}</h1>
        <h2>
          {yearlyPrice
            ? getText('plans_selector.plan_card.per_year')
            : getText('plans_selector.plan_card.per_month')}
        </h2>
        <PriceContent>
          {yearlyPrice}
          {yearlyPrice && getText('plans_selector.plan_card.per_year')}
        </PriceContent>
      </PriceContainer>
    </Container>
  );
};
