import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { breakpointsHelper } from '../../../../../../../common/utils/breakpointsHelper';

export const Container = styled.div`
  && {
    padding: 0px 32px;
    background-color: ${primitives.color.gray0};
    width: 100%;
    display: flex;
    align-items: center;

    @media (max-width: ${breakpointsHelper.medium_min_768px}) {
      width: auto;
      padding: 24px 16px 0px 16px;
    }

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      padding: 16px 16px 0px 16px;
      background-color: ${primitives.color.white};
    }
  }
`;

export const AttributeContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

export const Attribute = styled.div`
  && {
    display: flex;
    font-family: ${primitives.typography.family0};
    justify-content: flex-start;
    flex-direction: row;
  }
`;

export const AttributeText = styled.div`
  && {
    font-family: ${primitives.typography.family0};
    margin-left: 8px;
  }
`;
