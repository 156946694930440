import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { breakpointsHelper } from '../../../../../../../common/utils/breakpointsHelper';

interface ContainerProps {
  isLastChild?: boolean;
}
export const Container = styled.div<ContainerProps>`
  && {
    display: flex;
    flex-direction: column;
    background-color: ${primitives.color.gray0};
    align-items: center;
    justify-content: center;

    @media (max-width: ${breakpointsHelper.medium_min_768px}) {
      align-items: flex-start;
      padding: 24px 0px 16px 24px;
      padding-top: 24px;
      padding-left: 16px;
      padding-bottom: 24px;
    }

    @media (max-width: ${breakpointsHelper.extra_small_575px}) {
      background-color: ${primitives.color.white};
      padding: 24px 16px;

      button {
        width: 100%;
      }
    }
  }
`;

export const PlanPriceContainer = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    h1 {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.gray12};
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.gray12};
    }
  }
`;
