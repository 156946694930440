import React, { useEffect, useMemo, useReducer } from 'react';
import { ConsumerPlansContext } from '../context/consumerPlansContext';
import {
  consumerPlansInitialState,
  consumerPlansReducer
} from '../reducers/consumerPlansReducer';
import { setAssetsProviderAction } from '../actions/setAssetsProviderAction';
import { ConsumerPlanContent } from './components/ConsumerPlanContent';
import { ConsumerPlansProps } from '../types/consumerPlansProps';
import { useTheme } from '@veneer/theme';

export const ConsumerPlanSelector: React.FC<ConsumerPlansProps> = (props) => {
  const theme = useTheme();
  const isHPX =
    props.isHPX === undefined ? theme?.shape == 'pill' : props.isHPX;

  const [consumerPlansState, dispatch] = useReducer(
    consumerPlansReducer,
    consumerPlansInitialState({ ...props, isHPX })
  );
  useEffect(() => {
    dispatch(setAssetsProviderAction(props.language, props.country));
  }, [props.language, props.country]);
  const contextValue = useMemo(
    () => ({ consumerPlansState, dispatch }),
    [consumerPlansState, dispatch]
  );

  return (
    <ConsumerPlansContext.Provider value={contextValue}>
      <ConsumerPlanContent />
    </ConsumerPlansContext.Provider>
  );
};
